<template>
  <div class="not-found">
    <div class="not-found__error">
      <svg width="434" height="160" viewBox="0 0 434 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M185.548 156.915C219.804 156.915 247.573 140.833 247.573 120.994C247.573 101.156 219.804 85.0742 185.548 85.0742C151.292 85.0742 123.522 101.156 123.522 120.994C123.522 140.833 151.292 156.915 185.548 156.915Z" fill="#113079"/>
        <path d="M161.575 142.287L165.827 142.215L161.749 154.171C160.433 153.854 159.14 153.511 157.872 153.144L161.575 142.287ZM241.666 44.7483L237.414 44.8209L243.322 48.2311L227.525 94.5505L216.142 127.929L211.27 142.215L207.011 154.703C208.577 154.368 210.112 153.997 211.616 153.593L231.057 96.5893L247.574 48.1585L241.666 44.7483ZM227.49 73.9193L231.742 73.8468L196.149 53.3014L206.073 24.203L199.675 20.5605L195.914 20.8653L201.821 24.2755L191.898 53.3739L227.49 73.9193ZM179.693 101.556L175.441 101.628L164.165 134.693L177.195 142.215L199.757 155.238L204.009 155.166L168.416 134.62L179.693 101.556ZM217.876 114.506L182.283 93.9604L193.56 60.8955L189.308 60.968L178.031 94.0329L213.624 114.578L217.876 114.506Z" fill="#3D75E4"/>
        <path d="M99.2906 87.9757L78.2109 75.7279L90.2453 68.7812L111.325 81.029L99.2906 87.9757Z" fill="#F7CFD5"/>
        <path d="M70.9344 156.015L49.8547 143.768L49.7847 119.053L70.8643 131.3L70.9344 156.015Z" fill="#E14761"/>
        <path d="M46.5606 118.411L25.481 106.163L49.7086 92.1777L70.7882 104.425L46.5606 118.411Z" fill="#F7CFD5"/>
        <path d="M70.7884 104.428L49.7088 92.1803L49.5967 52.6465L70.6763 64.8942L70.7884 104.428Z" fill="#E14761"/>
        <path d="M21.1429 160L0.0632967 147.752L0 125.43L21.0796 137.677L21.1429 160Z" fill="#E14761"/>
        <path d="M72.4732 27.6032L51.3936 15.3555L77.9952 0L99.0748 12.2477L72.4732 27.6032Z" fill="#F7CFD5"/>
        <path d="M21.0796 137.677L0 125.43L51.3938 15.3555L72.4734 27.6032L21.0796 137.677Z" fill="#F7CFD5"/>
        <path d="M99.2898 87.9751L111.324 81.0284L111.4 107.9L99.3661 114.847L99.4361 139.562L70.9343 156.014L70.8643 131.299L21.1429 160L21.0796 137.678L72.4734 27.6035L99.0751 12.248L99.2898 87.9751ZM70.7881 104.427L70.6759 64.8935L46.5604 118.412L70.7881 104.427Z" fill="#F196A5"/>
        <path d="M374.197 76.7598L374.141 96.6874L315.726 102.566L315.782 82.6384L374.197 76.7598Z" fill="#F196A5"/>
        <path d="M316.773 110.125L316.716 130.053L266.995 101.351L267.051 81.4238L316.773 110.125Z" fill="#E14761"/>
        <path d="M433.999 75.0273L433.943 94.955L368.456 133.004L368.513 113.077L433.999 75.0273Z" fill="#F196A5"/>
        <path d="M357.31 133.525L357.254 153.452L345.219 146.506L345.276 126.578L357.31 133.525Z" fill="#E14761"/>
        <path d="M380.547 120.023L380.49 139.951L357.253 153.452L357.31 133.525L380.547 120.023Z" fill="#F196A5"/>
        <path d="M323.901 138.996L323.845 158.924L295.342 142.471L295.398 122.543L323.901 138.996Z" fill="#E14761"/>
        <path d="M345.276 126.578L345.219 146.506L323.845 158.925L323.902 138.997L345.276 126.578Z" fill="#F196A5"/>
        <path d="M368.514 113.077L380.548 120.024L357.311 133.525L345.277 126.578L323.902 138.997L295.399 122.544L316.774 110.125L267.052 81.4245L286.355 70.2091L407.397 59.6719L434 75.028L368.514 113.077ZM340.011 96.6243L374.198 76.7605L315.783 82.6392L340.011 96.6243Z" fill="#F7CFD5"/>
        <path d="M237.414 44.8228L227.49 73.9212L191.897 53.3759L201.821 24.2774L195.913 20.8672L185.99 49.9656L183.4 57.5598L172.123 90.6246L169.533 98.2199H169.533L158.257 131.285L155.667 138.879L151.523 151.029C153.559 151.804 155.678 152.512 157.872 153.146L161.574 142.289L186.894 156.905C193.953 156.818 200.721 156.048 207.01 154.705L212.698 138.027L243.321 48.233L237.414 44.8228ZM199.757 155.24L169.937 138.027H169.937L164.164 134.695L175.441 101.63L211.033 122.175L199.757 155.24ZM221.43 91.6916L213.624 114.58L178.031 94.0348L181.055 85.1686L189.307 60.9699L224.9 81.5153L221.43 91.6916Z" fill="#81ABEE"/>
      </svg>
    </div>

    <div class="not-found__description">
      Страница, которую вы пытались открыть,<br /> не существует или была удалена
    </div>

    <router-link to="/" class="btn btn--blue">
      Перейти на главную
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'PageNotFoundView',

}
</script>
